import resetActiveClasses from './resetActiveClasses';

export default function () {
  const isOnTheHomePage = document.location.pathname === '/';
  const bodyClassList = document.body.classList;
  const header = document.querySelector('.header');
  let currentCategoryImage = '';
  let currentSubCategoryImage = '';
  const imageWrapper = document.querySelector('.mega-menu__image');
  const mainCategories = document.querySelectorAll('.mega-menu__category');
  const subCategories = document.querySelectorAll('.mega-menu__category--sub');

  header && header.addEventListener('mouseleave', () => {
      const locale = header.dataset.locale;
      if (isOnTheHomePage || document.location.pathname === locale) {
        bodyClassList.remove('white-header');
      }
      resetActiveClasses();
    });

  subCategories.forEach((el) => {
    el.addEventListener('mouseenter', (e) => {
      currentSubCategoryImage = e.target.dataset.imgSrc;
      if(e.target.dataset.imgSrc) {
        imageWrapper.style.backgroundImage = `url('${currentSubCategoryImage}')`;
      } else {
        imageWrapper.style.backgroundImage = 'none';
      }
    });
    el.addEventListener('mouseleave', () => {
      imageWrapper.style.backgroundImage = `url('${currentCategoryImage}')`;
    });
  });
  mainCategories.forEach((el) => {
    el.addEventListener('click', (e) => {
      currentCategoryImage = e.target.dataset.imgSrc;
      imageWrapper.style.backgroundImage = `url('${currentCategoryImage}')`;
    });
  });

  const navItemsWithArrow = document.querySelectorAll('.has-arrow');
  navItemsWithArrow.forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      resetActiveClasses();
      const currentMegaMenu = document.querySelector(`#${e.target.dataset.id}`);
      const isCurrentMegaMenuActive = currentMegaMenu && !currentMegaMenu.classList.contains('mega-menu__wrapper--active');
      if (!bodyClassList.contains('white-header')) {
        bodyClassList.toggle('white-header');
      }
      if (document.querySelector('.mega-menu__wrapper--active')) {
        document.querySelector('.mega-menu__wrapper--active').classList.remove('mega-menu__wrapper--active');
        bodyClassList.remove('white-header');
      }
      if (isCurrentMegaMenuActive) {
        currentMegaMenu.classList.add('mega-menu__wrapper--active');
        bodyClassList.add('white-header');
      }
    });
  });
}
