export default function () {
  const tabs = document.querySelectorAll('[data-tab-target]');
  const tabContents = document.querySelectorAll('[data-tab-content]');

  tabs.forEach((tab) => {
    tab.addEventListener('click', () => {
      document.querySelector('.mega-menu__image').classList.add('mega-menu__image--active');
      const target = document.querySelector(tab.dataset.tabTarget);
      tabContents.forEach((tabContent) => {
        tabContent.classList.remove('mega-menu__category--active');
        tabContent.classList.remove('mega-menu__sub-categories--active');
      });
      tabs.forEach((tabb) => {
        tabb.classList.remove('mega-menu__category--active');
        tabb.classList.remove('mega-menu__sub-categories--active');
      });
      tab.classList.add('mega-menu__category--active');
      target.classList.add('mega-menu__category--active');
      target.classList.add('mega-menu__sub-categories--active');
    });
  });
}
