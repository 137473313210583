import { tns } from '../../../node_modules/tiny-slider/src/tiny-slider';

export default function newsSlider() {
  setTimeout(() => {
    if (document.querySelector('.skills-slider')) {
      tns({
        container: '.skills-slider',
        // items: 4,
        fixedWidth: 195,
        controlsText: ['<i class="icon-arrow-left"></i>', '<i class="icon-arrow-right"></i>'],
        loop: false,
        mouseDrag: true,
        nav: false,
        disable: false,
        speed: 400,
        preventScrollOnTouch: 'auto',
        gutter: 15,
        responsive: {
          591: {
            gutter: 21,
            // fixedWidth: 303,
            fixedWidth: 279,
          },
        },
      });
    }
    if (document.querySelector('.team__slider')) {
      tns({
        container: '.team__slider',
        // fixedWidth: 210,
        fixedWidth: 198,
        controlsText: ['<i class="icon-arrow-left"></i>', '<i class="icon-arrow-right"></i>'],
        slideBy: 1,
        loop: false,
        mouseDrag: true,
        nav: false,
        disable: false,
        speed: 400,
        preventScrollOnTouch: 'auto',
        gutter: 12,
        responsive: {
          591: {
            fixedWidth: 363.5,
            gutter: 24,
          },
          851: {
            fixedWidth: 363.5,
          },
          1260: {
            fixedWidth: 376,
          },
        },
      });
    }
    if (document.querySelector('.applicants-tips__slider')) {
      tns({
        container: '.applicants-tips__slider',
        // items: 4,
        fixedWidth: 215,
        controlsText: ['<i class="icon-arrow-left"></i>', '<i class="icon-arrow-right"></i>'],
        loop: false,
        mouseDrag: true,
        nav: false,
        disable: false,
        speed: 400,
        preventScrollOnTouch: 'auto',
        responsive: {
          591: {
            fixedWidth: 300,
          },
        },
      });
    }
    if (document.querySelector('.benefits__slider')) {
      tns({
        container: '.benefits__slider',
        // items: 4,
        fixedWidth: 205,
        controlsText: ['<i class="icon-arrow-left"></i>', '<i class="icon-arrow-right"></i>'],
        loop: false,
        mouseDrag: true,
        nav: false,
        disable: false,
        speed: 400,
        preventScrollOnTouch: 'auto',
        responsive: {
          591: {
            // fixedWidth: 300,
            fixedWidth: 260,
          },
          851: {
            // fixedWidth: 300,
            fixedWidth: 258.3,
          },
          1046: {
            fixedWidth: 310,
          },
          1260: {
            fixedWidth: 300,
          },
        },
      });
    }
  }, 200);
};
