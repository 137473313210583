export default function () {
  const tabs = document.querySelectorAll('[data-tab-target]')
  const tabContents = document.querySelectorAll('[data-tab-content]')
  const svgPath = document.querySelector('.tabs-block__svg-path');
  const svgCircle = document.querySelectorAll('.tabs-block__svg-circle');
  const mobileSvgPath = document.querySelector('.mobile-svg');
  const updateFill = (color) => {
    svgCircle.forEach(circle => {
      circle.style.fill = color
    });
  };
  updateFill('#f2ccd2');
  //svgCircle.style.fill = '#f2ccd2';
  tabs.forEach(tab => {
    tab.addEventListener('click', () => {
      const target = document.querySelector(tab.dataset.tabTarget);
      if (target.id == 'tab1') {
        svgPath.setAttribute('d', 'M-3094-6532v80');
        mobileSvgPath.setAttribute('d', 'M-3094-6532v19');
        updateFill('#f2ccd2');
      } else if (target.id == 'tab2') {
        svgPath.setAttribute('d', 'M-3094-6532v138');
        mobileSvgPath.setAttribute('d', 'M-3094-6532v110');
        updateFill('#f2ccd2');
      } else if (target.id == 'tab3') {
        svgPath.setAttribute('d', 'M-3094-6532v193');
        mobileSvgPath.setAttribute('d', 'M-3094-6532v210');
        updateFill('#f2ccd2');
      } else if (target.id == 'tab4') {
        svgPath.setAttribute('d', 'M-3094-6532v345');
        mobileSvgPath.setAttribute('d', 'M-3094-6532v290');
        updateFill('#c0001f');
        //svgCircle.style.opacity = 1;
      }
      tabContents.forEach(tabContent => {
        tabContent.classList.remove('active')
      });
      tabs.forEach(tab => {
        tab.classList.remove('active')
      });
      tab.classList.add('active')
      target.classList.add('active')
    })
  })
}
