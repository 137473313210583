import polyfills from './modules/polyfills';
import homeSlider from './modules/homeSlider';
import referenceSlider from './modules/referenceSlider';
import industriesSlider from './modules/industriesSlider';
import relatedProductsSlider from './modules/relatedProductsSlider';
import newsSlider from './modules/newsSlider';
// import allNewsSlider from './modules/allNewsSlider';
import selectLanguage from './modules/selectLanguage';
import toggleNavigation from './modules/toggleNavigation';
import handleMegaMenu from './modules/handleMegaMenu';
import mobileMenu from './modules/mobileMenu';
import recentProducts from './modules/recentProducts';
import switchCategories from './modules/switchCategories';
import introGallery from './modules/introGallery';
import files from './modules/files';
import handleSearchClick from './modules/handleSearchClick';
import searchPageSliders from './modules/searchPageSeliers';
import contactFromValidation from './modules/contactFormValidation';
import careerListSliders from './modules/careerListSliders';
import newsPageSlider from './modules/newsPageSlider';
import testimonialSlider from './modules/testimonialSlider';
import orderTable from './modules/orderTable';
import orderSummary from './modules/orderSummary';
import login from './modules/login';
import lazyLoading from './modules/lazyLoading';
import readMore from './modules/readMore';
import stickyBadge from './modules/stickyBadge';
import infoBox from './modules/infoBox';
import orderBasket from './modules/orderBasket';
import tabs from './modules/tabs';
import setMegamenuAboutPadding from './modules/setMegamenuAboutPadding';

const App = (() => ({
  init: () => {
    polyfills();
    industriesSlider();
    relatedProductsSlider();
    homeSlider();
    referenceSlider();
    newsSlider();
    selectLanguage();
    toggleNavigation();
    handleMegaMenu();
    switchCategories();
    mobileMenu();
    recentProducts();
    introGallery();
    files();
    contactFromValidation();
    careerListSliders();
    newsPageSlider();
    searchPageSliders();
    testimonialSlider();
    handleSearchClick();
    orderTable();
    orderSummary();
    login();
    lazyLoading();
    stickyBadge();
    infoBox();
    orderBasket();
    readMore();
    tabs();
    setMegamenuAboutPadding();
  },
}))();
App.init();

//Register ServiceWorker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('../../../sw.js')
    .then((reg) => console.log('service worker registered', reg))
    .catch((err) => console.log('service worker not registered', err));
}
