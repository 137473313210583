export default function () {
  const contact = document.querySelector('.contact');
  if (contact) {
    const box = contact.querySelector('.not-found');
    if (box) {
      box.querySelector('.icon-icon-close').addEventListener('click', () => {
        box.style.display = "none";
      });
    }
  }
}
