import { tns } from '../../../node_modules/tiny-slider/src/tiny-slider';


export default function () {
  setTimeout(() => {
    if (document.querySelector('.testimonial__slider')) {
      class Slider {
        constructor( ) {
          this.slider = tns({
            container: '.testimonial__slider',
            items: 1,
            slideBy: 1,
            loop: false,
            disable: false,
            mouseDrag: true,
            speed: 400,
            // gutter: 17,
            controlsText: ['<i class="icon-arrow-left"></i>', '<i class="icon-arrow-right"></i>'],
            navContainer: ".testimonial__slider__thumbnails",
            nav: true,
            navAsThumbnails: true,
          });

          this.thumbnails = tns({
            container: '.testimonial__slider__thumbnails',
            loop: false,
            mouseDrag: true,
            disable: false,
            slideBy: 1,
            nav: false,
            fixedWidth: 80,
            gutter: 11,

            responsive: {
              591: {
                gutter: 23,
                fixedWidth: 160,
              },
              851: {
                fixedWidth: 180,
              }
            }
          });

          this.slider.events.on( 'indexChanged', evt => this.nextSlide( evt ));

        }
        nextSlide( evt ) {
          const indexToGoTo = evt.index > this.slider.getInfo().slideCount ? 0 : evt.index - 1;

          this.thumbnails.goTo( indexToGoTo );
        }

      }

      new Slider();
      const controlsContainer = document.querySelector('.testimonial__slider__controls');
      const sliderControls = document.querySelector('.testimonial-page .tns-controls');
      if (controlsContainer) {
        controlsContainer.appendChild(sliderControls);
      }
    }
  }, 700);
}
