export default function () {
  if(document.querySelector('.btn--orders') && document.querySelector('.btn--orders').closest('.summary-box__bottom').querySelector('form')){
    const formBtn = document.querySelector('.btn--orders');
    formBtn.addEventListener('click', (e)=>{
      const validationElems = e.target.closest('.summary-box__bottom').querySelectorAll('select');
      validationElems.forEach((elem) => {
        if (!elem.value){
          elem.closest('.summary-box__form-row').classList.add('error');

          var selectElement = document.getElementById('delivery_address');
          if (selectElement) {
            var firstOption = selectElement.options[0];
            if (firstOption) {
              // Pobierz wartość i tekst pierwszej opcji
              var firstOptionText = firstOption.textContent;
              console.log(elem.closest('.summary-box__form-row').querySelector('.summary-box__validation'));
              elem.closest('.summary-box__form-row').querySelector('.summary-box__validation').textContent = firstOptionText;
            }
          }

          e.preventDefault();
        } else {
          elem.closest('.summary-box__form-row').classList.remove('error');
        }
      })
    })
  }
}