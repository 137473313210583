export default function () {
  const subCategories = document.querySelectorAll('.mega-menu__sub-categories');
  document.querySelectorAll('.mega-menu__category').forEach((el) => {
    el.classList.remove('mega-menu__category--active');
  });
  document.querySelector('.mega-menu__image').classList.remove('mega-menu__image--active');
  subCategories.forEach((el) => {
    el.classList.remove('mega-menu__sub-categories--active');
    el.classList.remove('mega-menu__category--active');
  });
  document.querySelectorAll('.mega-menu__wrapper').forEach((el) => {
    el.classList.remove('mega-menu__wrapper--active');
  });
}
