export default () => {
  const form = document.querySelector('.contact__form');
  if (form) {
    const nameInputWrapper = form.querySelector('.contact__form__field-name');
    const emailInputWrapper = form.querySelector('.contact__form__field-email');
    const phoneInputWrapper = form.querySelector('.contact__form__field-phone');
    const contentInputWrapper = form.querySelector('.contact__form__field-content');

    const nameInput = form.querySelector('#contact_name');
    const emailInput = form.querySelector('#contact_email');
    const phoneInput = form.querySelector('#contact_phone');
    const contentInput = form.querySelector('#contact_content');

    const nameValidation = () => {
      let valid = true;
      if (nameInput.value.length < 2) {
        valid = false;
        nameInputWrapper.classList.remove('valid');
        nameInputWrapper.classList.add('invalid');
      } else {
        nameInputWrapper.classList.add('valid');
        nameInputWrapper.classList.remove('invalid');
      }
      return valid;
    }
    const emailValidation = () => {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const valid = regex.test(emailInput.value);
      if(valid) {
        emailInputWrapper.classList.add('valid');
        emailInputWrapper.classList.remove('invalid');
      } else {
        emailInputWrapper.classList.remove('valid');
        emailInputWrapper.classList.add('invalid');
      }

      return valid;
    }
    const phoneValidation = () => {
      let valid = true;
      if (phoneInput.value.length < 8) {
        valid = false;
        phoneInputWrapper.classList.remove('valid');
        phoneInputWrapper.classList.add('invalid');
      } else {
        phoneInputWrapper.classList.add('valid');
        phoneInputWrapper.classList.remove('invalid');
      }
      return valid;
    }
    const contentValidation = () => {
      let valid = true;

      if (contentInput.value.length < 5) {
        valid = false;
        contentInputWrapper.classList.remove('valid');
        contentInputWrapper.classList.add('invalid');
      } else {
        contentInputWrapper.classList.add('valid');
        contentInputWrapper.classList.remove('invalid');
      }
      return valid;
    }
    const validation = () => {
      let valid = true;
      const nameResult = nameValidation(); 
      const emailRessult = emailValidation(); 
      const phoneResult = phoneValidation();
      const contentResult = contentValidation();

      if (!nameResult || !emailRessult || !phoneResult || !contentResult) {
        valid = false;
      }
      return valid;
    }
    nameInput.addEventListener('input', nameValidation)
    emailInput.addEventListener('input', emailValidation)
    phoneInput.addEventListener('input', phoneValidation)
    contentInput.addEventListener('input', contentValidation)
    form.addEventListener('submit', (e) => {
      const result = validation();
      if (!result) {
        e.preventDefault();
      }
    });
  }

}