export default function () {
  const allLangElements = document.querySelectorAll('.lang');

  const removeUrlParameter = (paramKey) => {
    const url = window.location.href
    var r = new URL(url)
    r.searchParams.delete(paramKey)
    const newUrl = r.href
    window.history.pushState({ path: newUrl }, '', newUrl)
  }
  removeUrlParameter('switch');

  allLangElements.forEach(langElement => {
    const langActive = langElement.querySelector(".lang__active");
    const langOptions = langElement.querySelectorAll('.lang__option');

    const toggleLangDropdown = () => {
      langElement.classList.toggle('active');
    }

    langOptions.forEach(option => {
      option.addEventListener('click', (e) => {
        const value = e.target.dataset.value;
        const searchObject = {
          switch: true,
        }
        const searchParams = new URLSearchParams(searchObject);
        const fullParams = `?${searchParams.toString()}`;
        toggleLangDropdown();
        const newUrl = `/${value}${fullParams}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
        document.location.reload(true);
      });
    });

    langActive.addEventListener('click', toggleLangDropdown);
  });


  document.body.addEventListener('click', (e) => {
    //e.stopPropagation();
    if (!e.target.classList.contains('lang__active') && !e.target.classList.contains('lang__option') && !e.target.classList.contains('lang__dropdown')) {
      allLangElements.forEach(langElement => {
        langElement.classList.remove('active');
      })
    }
  })

}
