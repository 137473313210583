import { tns } from '../../../node_modules/tiny-slider/src/tiny-slider';

export default function relatedProductsSlider() {
  setTimeout(() => {
    if (document.querySelector('.related-products__slider')) {
      tns({
        container: '.related-products__slider',
        loop: false,
        fixedWidth: 201,
        mouseDrag: true,
        disable: false,
        speed: 400,
        gutter: 17,
        controlsText: ['<i class="icon-arrow-left"></i>', '<i class="icon-arrow-right"></i>'],
        nav: false,
        preventScrollOnTouch: 'auto',
        swipeAngle: 45,
        responsive: {
          851: {
            gutter: 15,
            fixedWidth: 282,
          },
          1260: {
            gutter: 24,
          },
        },
      });
    }
  }, 200);
}
