export default function () {
  const badge = document.querySelector('.cart-bar__badge');
  const badgeQuantity = parseInt(badge.querySelector('.cart-bar__count').textContent);
  if (!badgeQuantity) {
    badge.classList.remove('cart-bar__badge--active');
  }
  document.addEventListener('scroll', function() {
    if(window.scrollY >= 105) {
      badge.classList.add('cart-bar__badge--sticky');
    } else {
      badge.classList.remove('cart-bar__badge--sticky');
    }
  });
}
