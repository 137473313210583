import { tns } from '../../../node_modules/tiny-slider/src/tiny-slider';

export default function industriesSlider() {
    setTimeout(() => {
      if (document.querySelector('.galery-slider')) {
        tns({
          container: '.galery-slider',
          loop: false,
          autoWidth: true,
          controlsText: ['<i class="icon-arrow-left"></i>', '<i class="icon-arrow-right"></i>'],
          //fixedWidth: 226,
          mouseDrag: true,
          disable: false,
          speed: 400,
          gutter: 12,
          nav: false,
          preventScrollOnTouch: 'auto',
          swipeAngle: 45,
          responsive: {
            591: {
              //autoWidth: true,
              gutter: 17,
            },
          },
        });
      }
    }, 200);
}
