import { tns } from '../../../node_modules/tiny-slider/src/tiny-slider';

export default window.onload = function newsSlider() {
  setTimeout(() => {
    if (document.querySelector('.search-slider--testimonials .news__content')) {
      tns({
        container: '.news__content',
        items: 2,
        fixedWidth: 190,
        speed: 400,
        loop: false,
        mouseDrag: true,
        nav: false,
        disable: false,
        controls: false,
        gutter: 40,
        preventScrollOnTouch: 'auto',
        swipeAngle: 45,
        responsive: {
          851: {
            disable: true,
          },
        },
      });
    }
    if (document.querySelector('.search-slider--news .news__content')) {
      tns({
        container: '.search-slider--news .news__content',
        items: 2,
        fixedWidth: 190,
        speed: 400,
        loop: false,
        mouseDrag: true,
        nav: false,
        disable: false,
        controls: false,
        gutter: 40,
        preventScrollOnTouch: 'auto',
        swipeAngle: 45,
        responsive: {
          851: {
            disable: true,
          },
        },
      });
    }
    if (document.querySelector('.search-slider--products .slider')) {
      tns({
        container: '.search-slider--products .slider',
        items: 2,
        fixedWidth: 200,
        speed: 400,
        loop: false,
        mouseDrag: true,
        nav: false,
        disable: false,
        controls: false,
        preventScrollOnTouch: 'auto',
        responsive: {
          851: {
            fixedWidth: 282
          },
          1045: {
            disable: true
          },
        },
      });
    }
    
  }, 200);
};
