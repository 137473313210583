export default function () {
  const header = document.querySelector('.header');
  const nav = header.querySelector('.nav');
  const navMobile = header.querySelector('.nav__mobile');
  const searchIconDesktop = nav.querySelector('.icon-search');
  const searchIconMobile = navMobile.querySelector('.icon-search');
  const searchCloseAll = header.querySelectorAll('.searchbar__close');
  const searchCloseMobile = navMobile.querySelector('.icon-icon-close');
  const handleIconClickDesktop = () => {
    header.classList.toggle('header--search-active');
  };
  const handleIconClickMobile = () => {
    header.classList.toggle('header--search-mobile');
  };
  searchIconMobile.addEventListener('click', handleIconClickMobile);
  searchCloseAll.forEach(searchClose => searchClose.addEventListener('click', handleIconClickDesktop));
  searchCloseMobile.addEventListener('click', handleIconClickMobile);
  searchIconDesktop.addEventListener('click', handleIconClickDesktop);
}
