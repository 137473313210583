export default function () {
  if (document.querySelector('.banner__slider__slid')) {
    const slids = document.querySelectorAll('.banner__slider__slid');
    const paginationItems = document.querySelectorAll('.banner__slider__pagination__item');
    let activeSlid = 1;

    const resetPaginationItems = () => {
      paginationItems.forEach((paginationItem) => {
        paginationItem.classList.remove('banner__slider__pagination__item--done');
      });
    };

    const changeSlid = () => {
      const prevSlid = document.querySelector('.banner__slider__slid--active');
      const prevPaginationItem = document.querySelector(`[data-pagination-slid='${activeSlid}']`);
      if (!prevSlid) return;
      if (!prevPaginationItem) return;

      prevPaginationItem.classList.remove('banner__slider__pagination__item--active');
      prevPaginationItem.classList.add('banner__slider__pagination__item--done');
      prevSlid.classList.remove('banner__slider__slid--active');

      const dataActiveSlid = parseInt(prevSlid.dataset.slid, 10);
      activeSlid = dataActiveSlid + 1;
      if (activeSlid > slids.length) {
        activeSlid = 1;
        resetPaginationItems();
      }
      const nextSlid = document.querySelector(`[data-slid='${activeSlid}']`);
      const nextPaginationItem = document.querySelector(`[data-pagination-slid='${activeSlid}']`);
      nextSlid.classList.add('banner__slider__slid--active');
      nextPaginationItem.classList.add('banner__slider__pagination__item--active');
    };

    let interval = setInterval(() => {
      changeSlid();
    }, 12000);

    const changeSlidOnClick = (paginationItem) => {
      const clickedSlidIndex = parseInt(paginationItem.dataset.paginationSlid, 10);
      if (clickedSlidIndex === activeSlid) return;
      activeSlid = clickedSlidIndex;
      const prevSlid = document.querySelector('.banner__slider__slid--active');
      const nextSlid = document.querySelector(`[data-slid='${activeSlid}']`);
      const nextPaginationItem = document.querySelector(`[data-pagination-slid='${activeSlid}']`);
      prevSlid.classList.remove('banner__slider__slid--active');

      paginationItems.forEach((item) => {
        const slidIdx = item.dataset.paginationSlid;
        item.classList.remove('banner__slider__pagination__item--done');
        item.classList.remove('banner__slider__pagination__item--active');
        if (slidIdx < activeSlid) {
          item.classList.add('banner__slider__pagination__item--done');
        }
      });

      setTimeout(() => {
        nextSlid.classList.add('banner__slider__slid--active');
        nextPaginationItem.classList.add('banner__slider__pagination__item--active');
      }, 0);

      clearInterval(interval);
      interval = setInterval(() => {
        changeSlid();
      }, 12000);
    };
    paginationItems.forEach((paginationItem) => {
      paginationItem.addEventListener('click', () => changeSlidOnClick(paginationItem));
    });
  }
}
