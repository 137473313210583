export default function () {
  const megaMenuAboutToggler = document.querySelector('[data-id=mega-menu-about]');
  const headerWrapper = document.querySelector('.header__wrapper');
  const megaMenuAbout = document.querySelector('#mega-menu-about');

  const megaMenuRec = megaMenuAboutToggler.getBoundingClientRect();
  const headerRec = headerWrapper.getBoundingClientRect();
  const megaMenuLeftPadding = `${megaMenuRec.x - headerRec.x + 15}px`;

  megaMenuAbout.style.paddingLeft = megaMenuLeftPadding;
}
