import { tns } from '../../../node_modules/tiny-slider/src/tiny-slider';

export default window.onload = function newsSlider() {
  setTimeout(() => {
    if (document.querySelector('.news__content')) {
      tns({
        container: '.news__content',
        items: 2,
        fixedWidth: 190,
        speed: 400,
        loop: false,
        mouseDrag: true,
        nav: false,
        disable: false,
        controls: false,
        gutter: 40,
        preventScrollOnTouch: 'auto',
        swipeAngle: 45,
        responsive: {
          591: {
            disable: true,
          },
        },
      });
    }
  }, 200);
};
