export default function () {
  const bars = document.querySelector('.bars');
  const mobileMenu = document.querySelector('.mobile-menu');
  const toggleNav = () => {
    const footer = document.querySelector('footer');
    bars.classList.toggle('bars--active');
    document.body.classList.toggle('mobile--active');
    document.querySelector('main').classList.toggle('hidden');
    if (footer) {
      footer.classList.toggle('hidden');
    }
    mobileMenu.classList.toggle('mobile-menu--active');
    // document.body.classList.toggle('white-header');
  };
  bars.addEventListener('click', toggleNav);
}
