export default function () {
  const button = document.querySelector('.stripes-block__btn');
  const text = document.querySelector('.stripes-block__text');
  if (button) {
    button.addEventListener('click', () => {
      text.classList.toggle('stripes-block__text--active');
      button.style.display = 'none';
    })
  }
}
