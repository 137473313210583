// export default function () {
//   const filesElements = document.querySelectorAll('.files');
//   const handleClick = (element) => {
//     const trigger = element.querySelector('.files__trigger');
//     const collapseBtn = element.querySelector('.files__collapse');
//     const wrapper = element.querySelector('.files__wrapper');
//     trigger.addEventListener('click', () => {
//       element.classList.add('files--active');
//       wrapper.style.maxHeight = `${wrapper.scrollHeight}px`;
//     });
//     collapseBtn.addEventListener('click', () => {
//       element.classList.remove('files--active');
//       wrapper.style.maxHeight = 0;
//       element.scrollIntoView({ behavior: 'smooth', block: 'start' });
//     });
//   };
//   filesElements.forEach((el) => {
//     handleClick(el);
//   });
// }


export default function () {
  const filesElements = document.querySelectorAll('.files');
  const careerOffers = document.querySelectorAll('.offer-card');

  const handleClick = (element, elementActiveClass, wrapplerClass, triggerClass, collapseBtnClass ) => {
    const trigger = element.querySelector(triggerClass);
    const collapseBtn = element.querySelector(collapseBtnClass);
    const wrapper = element.querySelector(wrapplerClass);
    let isCollapsed = false;

    const toggleFile = () => {
      if (isCollapsed) {
        isCollapsed = false;
        element.classList.remove(elementActiveClass);
        // element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        wrapper.style.maxHeight = 0;
      } else {
        isCollapsed = true;
        element.classList.add(elementActiveClass);
        wrapper.style.maxHeight = `${wrapper.scrollHeight}px`;
      }
    }

    trigger && trigger.addEventListener('click', toggleFile);
    collapseBtn && collapseBtn.addEventListener('click', toggleFile);
  };

  filesElements.forEach((el) => {
    handleClick(el, 'files--active' , '.files__wrapper', '.files__trigger', '.files__collapse');
  });
  careerOffers.forEach((el) => {
    handleClick(el, 'offer-card--expand', '.offer-card__content', '.offer-card__head');
  });
}
