export default function () {
  const galleryDesktop = document.querySelector('.intro-gallery--desktop');
  const galleryMobile = document.querySelector('.intro-gallery--mobile');
  const removeActiveClasses = (elementsList) => {
    elementsList.forEach((el) => el.classList.remove('intro-gallery__thumbnail--active'));
  };
  const run = (galleryWrapper) => {
    const current = galleryWrapper.querySelector('.intro-gallery__current');
    const thumbnails = galleryWrapper.querySelectorAll('.intro-gallery__thumbnail');

    if (thumbnails.length > 0) {
      thumbnails[0].classList.add('intro-gallery__thumbnail--active');
    }
    thumbnails.forEach((el) => {
      el.addEventListener('click', () => {
        removeActiveClasses(thumbnails);
        el.classList.add('intro-gallery__thumbnail--active');
        current.style.backgroundImage = `url('${el.dataset.photoSrc}')`;
      });
    });
  };
  if (galleryDesktop || galleryMobile) {
    run(galleryDesktop);
    run(galleryMobile);
  }
}
