export default function () {
 if(document.querySelector('.login')) {
   const loginTrigger = document.querySelector('.login');
   loginTrigger.addEventListener('click', (e)=> {
     if (e.target === loginTrigger && loginTrigger.classList.contains('active')) {
       loginTrigger.classList.remove('active');
       return;
     }
     loginTrigger.classList.add('active');
   });
   const action = window.location.href.split('#')[1];
   const isToken = window.location.href.split('?')[1];
   const newPasswordInput = document.querySelector('#new-password-token');
   if (isToken){
     if (isToken.includes('token=')){
       const token = isToken.replace('token=', '');
       newPasswordInput.value = token;
       openPopup(loginTrigger);
       loginTrigger.querySelector('.login__content--new').style.display = 'block';
       const resetForm = document.querySelector('#form-reset');
       resetPassword(resetForm, '.login__content', '#password_first', '#password_second');
     }
   }
   if(action === 'loginfailure') {
     openPopup(loginTrigger);
     loginTrigger.querySelector('.login__content--login').style.display = 'block';
     loginTrigger.querySelector('.login__failure').style.display = 'block';
   }
   if(action === 'requestsend') {
     openPopup(loginTrigger);
     loginTrigger.querySelector('.login__content--request-send').style.display = 'block';
   }
 }
 if (document.querySelector('.mobile-menu__user')) {
   const mobileTrigger = document.querySelector('.mobile-menu__user');
   const mobileLangTrigger = document.querySelector('.mobile-menu .lang');

   mobileLangTrigger && mobileLangTrigger.addEventListener('click', (e) => {
    const footerMenu = e.target.closest('.mobile-menu__wrapper').querySelector('.mobile-menu__footer');
    mobileLangTrigger.parentNode.classList.toggle('active');
    const langActive = mobileLangTrigger.querySelector('.lang__active');

    if ((e.target === mobileLangTrigger || e.target === langActive) && footerMenu.classList.contains('active') && !footerMenu.classList.contains('active--login')) {
       footerMenu.classList.remove('active');
       footerMenu.classList.remove('active--lang');
       return;
     }

    footerMenu.classList.remove('active--login');
    footerMenu.classList.add('active');
    footerMenu.classList.add('active--lang');
   });

   mobileTrigger.addEventListener('click', (e) => {
     const footerMenu = e.target.closest('.mobile-menu__wrapper').querySelector('.mobile-menu__footer');
     if ((e.target === mobileTrigger || e.target.classList.contains('icon--user')) && footerMenu.classList.contains('active') && !footerMenu.classList.contains('active--lang')) {
      footerMenu.classList.remove('active');
      footerMenu.classList.remove('active--login');
      return;
     }
      footerMenu.classList.remove('active--lang');
      footerMenu.classList.add('active--login');
      footerMenu.classList.add('active');
   });

   const action = window.location.href.split('#')[1];
   const isToken = window.location.href.split('?')[1];
   const newMobilePasswordInput = document.querySelector('#mobile-new-password-token');
   if (isToken) {
     if(isToken.includes('token=') && window.innerWidth < 1050){
       const token = isToken.replace('token=', '');
       newMobilePasswordInput.value = token;
       openMobilePopup(mobileTrigger);
       mobileTrigger.querySelector('.mobile-menu__content--new').style.display = 'block';
       const resetForm = document.querySelector('#form-reset-mobile');
       resetPassword(resetForm, '.mobile-menu__content', '#mobile-first-password', '#mobile-second-password')
     }
   }
   if(action === 'loginfailure' && window.innerWidth < 1050) {
     openMobilePopup(mobileTrigger);
     mobileTrigger.querySelector('.mobile-menu__content--login').style.display = 'block';
     mobileTrigger.querySelector('.login__failure').style.display = 'block';
   }
   if(action === 'requestsend' && window.innerWidth < 1050) {
     openMobilePopup(mobileTrigger);
     mobileTrigger.querySelector('.mobile-menu__content--request-send').style.display = 'block';
   }
 }
 document.body.addEventListener('click', (e) => {
    var closestElement = e.target.closest('.login__wrapper');
    if (!closestElement && !e.target.classList.contains('login') && !e.target.classList.contains('icon-user')) {
      document.querySelector('.login').classList.remove('active');
    }
  });
 }
 if(document.querySelector('.forget-link')){
   const forgetLinks = document.querySelectorAll('.forget-link');
   const backLinks = document.querySelectorAll('.forget-link--back');
   Array.prototype.forEach.call(forgetLinks, (link)=> {
     link.addEventListener('click', (e) => {
       if(window.innerWidth < 1050) {
         e.target.closest('.mobile-menu__login').querySelector('.mobile-menu__content').style.display = 'none';
         e.target.closest('.mobile-menu__login').querySelector('.mobile-menu__content--reset').style.display = 'block';
         return;
       }
       e.target.closest('.login__wrapper').querySelector('.login__content').style.display = 'none';
       e.target.closest('.login__wrapper').querySelector('.login__content--reset').style.display = 'block';
     });
   });
   Array.prototype.forEach.call(backLinks, (link)=> {
     link.addEventListener('click', (e) => {
       if(window.innerWidth < 1050) {
         e.target.closest('.mobile-menu__login').querySelectorAll('.mobile.menu__content').forEach((content) => {
           content.style.display = 'none'
         });
         e.target.closest('.mobile-menu__login').querySelector('.mobile-menu__content--login').style.display = 'block';
         return;
       }
       e.target.closest('.login__wrapper').querySelectorAll('.login__content').forEach((content) => {
         content.style.display = 'none'
       });
       e.target.closest('.login__wrapper').querySelector('.login__content--login').style.display = 'block';
     });
   });
}
function openPopup(elem){
  elem.classList.add('active');
  elem.querySelectorAll('.login__content').forEach((content) => {
    content.style.display = 'none'
  });
}

function openMobilePopup(elem) {
  document.querySelector('.bars').click();
  document.querySelector('.mobile-menu__footer').classList.add('active--login', 'active');
  elem.querySelectorAll('.mobile-menu__content').forEach((content) => {
    content.style.display = 'none'
  });
}

 function resetPassword(form, container, pass1, pass2) {
   form.addEventListener('submit', (e)=> {
     if (e.target.querySelector(pass1).value !== e.target.querySelector(pass2).value) {
       e.preventDefault();
       e.target.closest(container).querySelector('.login__not-equal').style.display = 'block';
     } else {
       e.target.closest(container).querySelector('.login__not-equal').style.display = 'none';
     }
   })
 }
