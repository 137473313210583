import { tns } from '../../../node_modules/tiny-slider/src/tiny-slider';

export default function industriesSlider() {
  setTimeout(() => {
    if (document.querySelector('.reference__slider')) {
      tns({
        container: '.reference__slider',
        edgePadding: 20,
        fixedWidth: 204,
        gutter: 11,
        speed: 400,
        loop: false,
        mouseDrag: true,
        preventScrollOnTouch: 'auto',
        swipeAngle: 45,
        nav: false,
        disable: false,
        navContainer: '.reference__content__info__pagination .nav-arrows',
        controlsText: ['<span class="nav-arrows__item"><i class="icon-arrow-left"></i></span>', '<span class="nav-arrows__item"><i class="icon-arrow-right"></i></span>'],
        responsive: {
          1046: {
            edgePadding: -10,
            gutter: 23,
            fixedWidth: 384,
          },
        },
      });
    }
    const navContainer = document.querySelector('.reference__content__info__pagination .nav-arrows');
    const sliderNav = document.querySelector('.reference .tns-controls');
    if (navContainer) {
      navContainer.appendChild(sliderNav);
    }
  }, 10);
}
